import { Security } from "@okta/okta-react";
import { oktaAuth } from "../config/okta";

const OktaProvider = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={(_, uri) => window.location.replace(uri)}>
      {children}
    </Security>
  );
};

export default OktaProvider;
