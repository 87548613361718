import { GameFilters } from "@/types/game";
import {
  ApproveIgspRequest,
  CreateIgspRequest,
  DenyIgspRequest,
  IgspClientBroadcaster,
  IgspContract,
  IgspContractRuleViolations,
  IgspCountry,
  IgspDecoderBrand,
  IgspDecoderModel,
  IgspGame,
  IgspJewelEvent,
  IgspNetwork,
  IgspRegion,
  IgspRequest,
  IgspRequestFilters,
  IgspShow,
  IgspTransmissionMethod,
  IgspTransmit,
  IgspTransmitDestination,
  IgspTransmitFormat,
  SaveIgspClientBroadcaster,
  SaveIgspContract,
  SaveIgspCountry,
  SaveIgspDecoderBrand,
  SaveIgspDecoderModel,
  SaveIgspJewelEvent,
  SaveIgspNetwork,
  SaveIgspRegion,
  SaveIgspRequest,
  SaveIgspShow,
  SaveIgspTransmissionMethod,
  SaveIgspTransmit,
  SaveIgspTransmitDestination,
  SaveIgspTransmitFormat
} from "../types/igsp";
import { generateQueryParams } from "../utils/http";
import { batterApi } from "./batter";

const getIgspClientBroadcasters = async (active?: boolean) => {
  const result = await batterApi.get<{ broadcasters: IgspClientBroadcaster[] }>(
    `/v2/igsp/client/broadcasters${generateQueryParams({ active })}`
  );
  return result.data.broadcasters;
};

const saveIgspClientBroadcaster = async (payload: SaveIgspClientBroadcaster, id?: number) => {
  const result = id
    ? await batterApi.put<IgspClientBroadcaster>(`/v2/igsp/client/broadcasters/${id}`, payload)
    : await batterApi.post<IgspClientBroadcaster>("/v2/igsp/client/broadcasters", payload);
  return result.data;
};

const deleteIgspClientBroadcaster = async (id: number) => {
  const result = await batterApi.delete<IgspClientBroadcaster>(`/v2/igsp/client/broadcasters/${id}`);
  return result.data;
};

const saveIgspContract = async (payload: SaveIgspContract, id?: number) => {
  const result = id
    ? await batterApi.put<IgspContract>(`/v2/igsp/contracts/${id}`, payload)
    : await batterApi.post<IgspContract>("/v2/igsp/contracts", payload);
  return result.data;
};

const getIgspCountries = async (active?: boolean) => {
  const result = await batterApi.get<{ countries: IgspCountry[] }>(
    `/v2/igsp/countries${generateQueryParams({ active })}`
  );
  return result.data.countries;
};

const saveIgspCountry = async (payload: SaveIgspCountry, id?: number) => {
  const result = id
    ? await batterApi.put<IgspCountry>(`/v2/igsp/countries/${id}`, payload)
    : await batterApi.post<IgspCountry>("/v2/igsp/countries", payload);
  return result.data;
};

const deleteIgspCountry = async (id: number) => {
  const result = await batterApi.delete<IgspCountry>(`/v2/igsp/countries/${id}`);
  return result.data;
};

const getIgspDecoderBrands = async (active?: boolean) => {
  const result = await batterApi.get<{ brands: IgspDecoderBrand[] }>(
    `/v2/igsp/decoder/brands${generateQueryParams({ active })}`
  );
  return result.data.brands;
};

const saveIgspDecoderBrand = async (payload: SaveIgspDecoderBrand, id?: number) => {
  const result = id
    ? await batterApi.put<IgspDecoderBrand>(`/v2/igsp/decoder/brands/${id}`, payload)
    : await batterApi.post<IgspDecoderBrand>("/v2/igsp/decoder/brands", payload);
  return result.data;
};

const deleteIgspDecoderBrand = async (id: number) => {
  const result = await batterApi.delete<IgspDecoderBrand>(`/v2/igsp/decoder/brands/${id}`);
  return result.data;
};

const getIgspDecoderModels = async (active?: boolean) => {
  const result = await batterApi.get<{ models: IgspDecoderModel[] }>(
    `/v2/igsp/decoder/models${generateQueryParams({ active })}`
  );
  return result.data.models;
};

const saveIgspDecoderModel = async (payload: SaveIgspDecoderModel, id?: number) => {
  const result = id
    ? await batterApi.put<IgspDecoderModel>(`/v2/igsp/decoder/models/${id}`, payload)
    : await batterApi.post<IgspDecoderModel>("/v2/igsp/decoder/models", payload);
  return result.data;
};

const deleteIgspDecoderModel = async (id: number) => {
  const result = await batterApi.delete<IgspDecoderModel>(`/v2/igsp/decoder/models/${id}`);
  return result.data;
};

const getIgspJewelEvents = async (active?: boolean) => {
  const result = await batterApi.get<{ events: IgspJewelEvent[] }>(`/v2/igsp/events${generateQueryParams({ active })}`);
  return result.data.events;
};

const saveIgspJewelEvent = async (payload: SaveIgspJewelEvent, id?: number) => {
  const result = id
    ? await batterApi.put<IgspJewelEvent>(`/v2/igsp/events/${id}`, payload)
    : await batterApi.post<IgspJewelEvent>("/v2/igsp/events", payload);
  return result.data;
};

const deleteIgspJewelEvent = async (id: number) => {
  const result = await batterApi.delete<IgspJewelEvent>(`/v2/igsp/events/${id}`);
  return result.data;
};

const getIgspNetworks = async (active?: boolean) => {
  const result = await batterApi.get<{ networks: IgspNetwork[] }>(
    `/v2/igsp/networks${generateQueryParams({ active })}`
  );
  return result.data.networks;
};

const saveIgspNetwork = async (payload: SaveIgspNetwork, id: number) => {
  const result = await batterApi.put<IgspNetwork>(`/v2/igsp/networks/${id}`, payload);
  return result.data;
};

const getIgspRegions = async (active?: boolean) => {
  const result = await batterApi.get<{ regions: IgspRegion[] }>(`/v2/igsp/regions${generateQueryParams({ active })}`);
  return result.data.regions;
};

const saveIgspRegion = async (payload: SaveIgspRegion, id?: number) => {
  const result = id
    ? await batterApi.put<IgspRegion>(`/v2/igsp/regions/${id}`, payload)
    : await batterApi.post<IgspRegion>("/v2/igsp/regions", payload);
  return result.data;
};

const deleteIgspRegion = async (id: number) => {
  const result = await batterApi.delete<IgspRegion>(`/v2/igsp/regions/${id}`);
  return result.data;
};

const getIgspShows = async (active?: boolean) => {
  const result = await batterApi.get<{ shows: IgspShow[] }>(`/v2/igsp/shows${generateQueryParams({ active })}`);
  return result.data.shows;
};

const saveIgspShow = async (payload: SaveIgspShow, id?: number) => {
  const result = id
    ? await batterApi.put<IgspShow>(`/v2/igsp/shows/${id}`, payload)
    : await batterApi.post<IgspShow>("/v2/igsp/shows", payload);
  return result.data;
};

const deleteIgspShow = async (id: number) => {
  const result = await batterApi.delete<IgspShow>(`/v2/igsp/shows/${id}`);
  return result.data;
};

const getIgspTransmissionMethods = async (active?: boolean) => {
  const result = await batterApi.get<{ methods: IgspTransmissionMethod[] }>(
    `/v2/igsp/transmission/methods${generateQueryParams({ active })}`
  );
  return result.data.methods;
};

const saveIgspTransmissionMethod = async (payload: SaveIgspTransmissionMethod, id?: number) => {
  const result = id
    ? await batterApi.put<IgspTransmissionMethod>(`/v2/igsp/transmission/methods/${id}`, payload)
    : await batterApi.post<IgspTransmissionMethod>("/v2/igsp/transmission/methods", payload);
  return result.data;
};

const deleteIgspTransmissionMethod = async (id: number) => {
  const result = await batterApi.delete<IgspTransmissionMethod>(`/v2/igsp/transmission/methods/${id}`);
  return result.data;
};

const getIgspTransmits = async (active?: boolean) => {
  const result = await batterApi.get<{ transmits: IgspTransmit[] }>(
    `/v2/igsp/transmits${generateQueryParams({ active })}`
  );
  return result.data.transmits;
};

const saveIgspTransmit = async (payload: SaveIgspTransmit, id?: number) => {
  const result = id
    ? await batterApi.put<IgspTransmit>(`/v2/igsp/transmits/${id}`, payload)
    : await batterApi.post<IgspTransmit>("/v2/igsp/transmits", payload);
  return result.data;
};

const deleteIgspTransmit = async (id: number) => {
  const result = await batterApi.delete<IgspTransmit>(`/v2/igsp/transmits/${id}`);
  return result.data;
};

const getIgspTransmitDestinations = async (active?: boolean) => {
  const result = await batterApi.get<{ destinations: IgspTransmitDestination[] }>(
    `/v2/igsp/transmit/destinations${generateQueryParams({ active })}`
  );
  return result.data.destinations;
};

const saveIgspTransmitDestination = async (payload: SaveIgspTransmitDestination, id?: number) => {
  const result = id
    ? await batterApi.put<IgspTransmitDestination>(`/v2/igsp/transmit/destinations/${id}`, payload)
    : await batterApi.post<IgspTransmitDestination>("/v2/igsp/transmit/destinations", payload);
  return result.data;
};

const deleteIgspTransmitDestination = async (id: number) => {
  const result = await batterApi.delete<IgspTransmitDestination>(`/v2/igsp/transmit/destinations/${id}`);
  return result.data;
};

const getIgspTransmitFormats = async (active?: boolean) => {
  const result = await batterApi.get<{ formats: IgspTransmitFormat[] }>(
    `/v2/igsp/transmit/formats${generateQueryParams({ active })}`
  );
  return result.data.formats;
};

const saveIgspTransmitFormat = async (payload: SaveIgspTransmitFormat, id?: number) => {
  const result = id
    ? await batterApi.put<IgspTransmitFormat>(`/v2/igsp/transmit/formats/${id}`, payload)
    : await batterApi.post<IgspTransmitFormat>("/v2/igsp/transmit/formats", payload);
  return result.data;
};

const deleteIgspTransmitFormat = async (id: number) => {
  const result = await batterApi.delete<IgspTransmitFormat>(`/v2/igsp/transmit/formats/${id}`);
  return result.data;
};

const getIgspRequests = async (filters: IgspRequestFilters) => {
  const results = await batterApi.get<{ requests: IgspRequest[] }>(`/v2/igsp/requests${generateQueryParams(filters)}`);
  return results.data.requests;
};

const createIgspRequest = async (payload: CreateIgspRequest) => {
  const results = await batterApi.post<IgspRequest>("/v2/igsp/requests", payload);
  return results.data;
};

const approveIgspRequest = async (id: number, payload: ApproveIgspRequest) => {
  const results = await batterApi.patch<IgspRequest>(`/v2/igsp/requests/${id}/approve`, payload);
  return results.data;
};

const bulkApproveIgspRequests = async (requestIds: number[], payload: ApproveIgspRequest = {}) => {
  const results = await batterApi.patch<{ requests: IgspRequest }>(
    `/v2/igsp/requests/approve${generateQueryParams({ requestIds })}`,
    payload
  );
  return results.data.requests;
};

const denyIgspRequest = async (id: number, payload: DenyIgspRequest) => {
  const results = await batterApi.patch<IgspRequest>(`/v2/igsp/requests/${id}/deny`, payload);
  return results.data;
};

const bulkDenyIgspRequests = async (requestIds: number[], payload: DenyIgspRequest) => {
  const results = await batterApi.patch<{ requests: IgspRequest }>(
    `/v2/igsp/requests/deny${generateQueryParams({ requestIds })}`,
    payload
  );
  return results.data.requests;
};

const finalizeIgspRequest = async (id: number) => {
  const results = await batterApi.patch<IgspRequest>(`/v2/igsp/requests/${id}/finalize`);
  return results.data;
};

const saveIgspRequest = async (id: number, payload: SaveIgspRequest) => {
  const results = await batterApi.patch<IgspRequest>(`/v2/igsp/requests/${id}`, payload);
  return results.data;
};

const getIgspViolationsForGame = async (batterPk: number) => {
  const results = await batterApi.get<IgspContractRuleViolations>(`/v2/igsp/games/${batterPk}/violations`);
  return results.data;
};

const getIgspViolationsForRequest = async (requestId: number) => {
  const results = await batterApi.get<IgspContractRuleViolations>(`/v2/igsp/requests/${requestId}/violations`);
  return results.data;
};

const getIgspGames = async (filters: GameFilters) => {
  const results = await batterApi.get<{ games: IgspGame[] }>(`/v2/igsp/games${generateQueryParams(filters)}`);
  return results.data.games;
};

export {
  approveIgspRequest,
  bulkApproveIgspRequests,
  bulkDenyIgspRequests,
  createIgspRequest,
  deleteIgspClientBroadcaster,
  deleteIgspCountry,
  deleteIgspDecoderBrand,
  deleteIgspDecoderModel,
  deleteIgspJewelEvent,
  deleteIgspRegion,
  deleteIgspShow,
  deleteIgspTransmissionMethod,
  deleteIgspTransmit,
  deleteIgspTransmitDestination,
  deleteIgspTransmitFormat,
  denyIgspRequest,
  finalizeIgspRequest,
  getIgspClientBroadcasters,
  getIgspCountries,
  getIgspDecoderBrands,
  getIgspDecoderModels,
  getIgspGames,
  getIgspJewelEvents,
  getIgspNetworks,
  getIgspRegions,
  getIgspRequests,
  getIgspShows,
  getIgspTransmissionMethods,
  getIgspTransmitDestinations,
  getIgspTransmitFormats,
  getIgspTransmits,
  getIgspViolationsForGame,
  getIgspViolationsForRequest,
  saveIgspClientBroadcaster,
  saveIgspContract,
  saveIgspCountry,
  saveIgspDecoderBrand,
  saveIgspDecoderModel,
  saveIgspJewelEvent,
  saveIgspNetwork,
  saveIgspRegion,
  saveIgspRequest,
  saveIgspShow,
  saveIgspTransmissionMethod,
  saveIgspTransmit,
  saveIgspTransmitDestination,
  saveIgspTransmitFormat
};
