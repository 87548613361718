import { Schedule, ScheduleAudit, ScheduleStatus, ScheduleType, UpdateSchedule } from "../types/schedule";
import { batterApi } from "./batter";

export const getSchedules = async () => {
  const results = await batterApi.get<{ schedules: Schedule[] }>("/v2/schedules");
  return results.data.schedules;
};

export const getScheduleTypes = async () => {
  const results = await batterApi.get<{ scheduleTypes: ScheduleType[] }>("/v2/scheduleTypes");
  return results.data.scheduleTypes;
};

export const getScheduleStatuses = async () => {
  const results = await batterApi.get<{ scheduleStatuses: ScheduleStatus[] }>("/v2/scheduleStatuses");
  return results.data.scheduleStatuses;
};

export const getScheduleEditors = async () => {
  const results = await batterApi.get<{ editors: string[] }>("/v2/scheduleEditors");
  return [...results.data.editors].sort((a, b) => a.localeCompare(b));
};

export const getAuditsForSchedule = async (scheduleId: number) => {
  const results = await batterApi.get<{ audits: ScheduleAudit[] }>(`/v2/schedules/${scheduleId}/audits`);
  return results.data.audits;
};

export const updateSchedules = async (data: UpdateSchedule[]) => {
  const results = await batterApi.put<{ schedules: Schedule[] }>("/v2/schedules", data);
  return results.data.schedules;
};
