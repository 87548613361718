import { useQueries } from "@tanstack/react-query";
import { ValueOpt } from "best-common-react";
import { createContext, useEffect, useReducer } from "react";
import {
  getIgspClientBroadcasters,
  getIgspCountries,
  getIgspDecoderBrands,
  getIgspDecoderModels,
  getIgspJewelEvents,
  getIgspNetworks,
  getIgspRegions,
  getIgspShows,
  getIgspTransmissionMethods,
  getIgspTransmitDestinations,
  getIgspTransmitFormats,
  getIgspTransmits
} from "../api/igsp";
import { useAuth } from "../hooks/useAuth";
import {
  IgspClientBroadcaster,
  IgspCountry,
  IgspDecoderBrand,
  IgspDecoderModel,
  IgspGame,
  IgspJewelEvent,
  IgspNetwork,
  IgspRegion,
  IgspRequest,
  IgspShow,
  IgspTransmissionMethod,
  IgspTransmit,
  IgspTransmitDestination,
  IgspTransmitFormat
} from "../types/igsp";
import { Action, ReactContext, ReactProps } from "../types/react";
import { getDecoderName } from "../utils/igsp";
import { listToDict } from "../utils/list";

type IgspAction = Action & {
  clientBroadcaster?: IgspClientBroadcaster;
  clientBroadcasters?: IgspClientBroadcaster[];
  country?: IgspCountry;
  countries?: IgspCountry[];
  decoderBrand?: IgspDecoderBrand;
  decoderBrands?: IgspDecoderBrand[];
  decoderModel?: IgspDecoderModel;
  decoderModels?: IgspDecoderModel[];
  game?: IgspGame;
  games?: IgspGame[];
  jewelEvent?: IgspJewelEvent;
  jewelEvents?: IgspJewelEvent[];
  network?: IgspNetwork;
  networks?: IgspNetwork[];
  parent?: IgspRequest;
  region?: IgspRegion;
  regions?: IgspRegion[];
  request?: IgspRequest;
  show?: IgspShow;
  shows?: IgspShow[];
  transmissionMethod?: IgspTransmissionMethod;
  transmissionMethods?: IgspTransmissionMethod[];
  transmit?: IgspTransmit;
  transmits?: IgspTransmit[];
  transmitDestination?: IgspTransmitDestination;
  transmitDestinations?: IgspTransmitDestination[];
  transmitFormat?: IgspTransmitFormat;
  transmitFormats?: IgspTransmitFormat[];
};

type IgspState = ReactContext<IgspAction> & {
  loading: boolean;
  requestDrawerOpen: boolean;
  requestDrawerGames: IgspGame[];
  selectedClientBroadcaster: IgspClientBroadcaster | null;
  selectedCountry: IgspCountry | null;
  selectedDecoderBrand: IgspDecoderBrand | null;
  selectedDecoderModel: IgspDecoderModel | null;
  selectedGame: IgspGame | null;
  selectedJewelEvent: IgspJewelEvent | null;
  selectedNetwork: IgspNetwork | null;
  selectedParentRequest: IgspRequest | null;
  selectedRegion: IgspRegion | null;
  selectedRequest: IgspRequest | null;
  selectedShow: IgspShow | null;
  selectedTransmissionMethod: IgspTransmissionMethod | null;
  selectedTransmit: IgspTransmit | null;
  selectedTransmitDestination: IgspTransmitDestination | null;
  selectedTransmitFormat: IgspTransmitFormat | null;
  clientBroadcastersById: Record<number, IgspClientBroadcaster>;
  clientBroadcasterOptions: ValueOpt<number>[];
  countriesById: Record<number, IgspCountry>;
  countryOptions: ValueOpt<number>[];
  decoderBrandsById: Record<number, IgspDecoderBrand>;
  decoderBrandOptions: ValueOpt<number>[];
  decoderModelsById: Record<number, IgspDecoderModel>;
  decoderModelOptions: ValueOpt<number>[];
  jewelEventsById: Record<number, IgspJewelEvent>;
  jewelEventOptions: ValueOpt<number>[];
  networksById: Record<number, IgspNetwork>;
  networkOptions: ValueOpt<number>[];
  regionsById: Record<number, IgspRegion>;
  regionOptions: ValueOpt<number>[];
  showsById: Record<number, IgspShow>;
  showOptions: ValueOpt<number>[];
  transmissionMethodsById: Record<number, IgspTransmissionMethod>;
  transmissionMethodOptions: ValueOpt<number>[];
  transmitsById: Record<number, IgspTransmit>;
  transmitOptions: ValueOpt<number>[];
  transmitDestinationsById: Record<number, IgspTransmitDestination>;
  transmitDestinationOptions: ValueOpt<number>[];
  transmitFormatsById: Record<number, IgspTransmitFormat>;
  transmitFormatOptions: ValueOpt<number>[];
};

const initialState: IgspState = {
  dispatch: () => {},
  loading: false,
  requestDrawerOpen: false,
  requestDrawerGames: [],
  selectedClientBroadcaster: null,
  selectedCountry: null,
  selectedDecoderBrand: null,
  selectedDecoderModel: null,
  selectedGame: null,
  selectedJewelEvent: null,
  selectedNetwork: null,
  selectedParentRequest: null,
  selectedRegion: null,
  selectedRequest: null,
  selectedShow: null,
  selectedTransmissionMethod: null,
  selectedTransmit: null,
  selectedTransmitDestination: null,
  selectedTransmitFormat: null,
  clientBroadcastersById: {},
  clientBroadcasterOptions: [],
  countriesById: {},
  countryOptions: [],
  decoderBrandsById: {},
  decoderBrandOptions: [],
  decoderModelsById: {},
  decoderModelOptions: [],
  jewelEventsById: {},
  jewelEventOptions: [],
  networksById: {},
  networkOptions: [],
  regionsById: {},
  regionOptions: [],
  showsById: {},
  showOptions: [],
  transmissionMethodsById: {},
  transmissionMethodOptions: [],
  transmitsById: {},
  transmitOptions: [],
  transmitDestinationsById: {},
  transmitDestinationOptions: [],
  transmitFormatsById: {},
  transmitFormatOptions: []
};

const IgspContext = createContext(initialState);

const reducer = (state: IgspState, action: IgspAction): IgspState => {
  const {
    clientBroadcasters = [],
    clientBroadcaster = null,
    country = null,
    countries = [],
    decoderBrand = null,
    decoderBrands = [],
    decoderModel = null,
    decoderModels = [],
    game = null,
    games = [],
    jewelEvent = null,
    jewelEvents = [],
    network = null,
    networks = [],
    parent = null,
    region = null,
    regions = [],
    request = null,
    show = null,
    shows = [],
    transmissionMethod = null,
    transmissionMethods = [],
    transmit = null,
    transmits = [],
    transmitDestination = null,
    transmitDestinations = [],
    transmitFormat = null,
    transmitFormats = []
  } = action;

  switch (action.type) {
    case "setRequestDrawerGames":
      return { ...state, requestDrawerGames: games };
    case "setRequestDrawerInfo":
      return {
        ...state,
        requestDrawerGames: action.games == null ? state.requestDrawerGames : games,
        requestDrawerOpen: !!game || !!request,
        selectedGame: game,
        selectedRequest: request,
        selectedParentRequest: parent
      };
    case "setSelectedClientBroadcaster":
      return { ...state, selectedClientBroadcaster: clientBroadcaster };
    case "setSelectedCountry":
      return { ...state, selectedCountry: country };
    case "setSelectedDecoderBrand":
      return { ...state, selectedDecoderBrand: decoderBrand };
    case "setSelectedDecoderModel":
      return { ...state, selectedDecoderModel: decoderModel };
    case "setSelectedJewelEvent":
      return { ...state, selectedJewelEvent: jewelEvent };
    case "setSelectedNetwork":
      return { ...state, selectedNetwork: network };
    case "setSelectedRegion":
      return { ...state, selectedRegion: region };
    case "setSelectedShow":
      return { ...state, selectedShow: show };
    case "setSelectedTransmissionMethod":
      return { ...state, selectedTransmissionMethod: transmissionMethod };
    case "setSelectedTransmit":
      return { ...state, selectedTransmit: transmit };
    case "setSelectedTransmitDestination":
      return { ...state, selectedTransmitDestination: transmitDestination };
    case "setSelectedTransmitFormat":
      return { ...state, selectedTransmitFormat: transmitFormat };
    case "clearSelected":
      return {
        ...state,
        selectedClientBroadcaster: null,
        selectedCountry: null,
        selectedDecoderBrand: null,
        selectedDecoderModel: null,
        selectedJewelEvent: null,
        selectedNetwork: null,
        selectedRegion: null,
        selectedShow: null,
        selectedTransmissionMethod: null,
        selectedTransmit: null,
        selectedTransmitDestination: null,
        selectedTransmitFormat: null
      };
    case "setClientBroadcasters": {
      return {
        ...state,
        clientBroadcastersById: listToDict(clientBroadcasters, i => i.id),
        clientBroadcasterOptions: clientBroadcasters.filter(c => c.active).map(c => ({ label: c.name, value: c.id }))
      };
    }
    case "setCountries": {
      return {
        ...state,
        countriesById: listToDict(countries, i => i.id),
        countryOptions: countries.filter(c => c.active).map(c => ({ label: c.name, value: c.id }))
      };
    }
    case "setDecoderBrands": {
      return {
        ...state,
        decoderBrandsById: listToDict(decoderBrands, i => i.id),
        decoderBrandOptions: decoderBrands.filter(b => b.active).map(b => ({ label: b.name, value: b.id }))
      };
    }
    case "setDecoderModels": {
      return {
        ...state,
        decoderModelsById: listToDict(decoderModels, i => i.id),
        decoderModelOptions: decoderModels.filter(m => m.active).map(m => ({ label: getDecoderName(m), value: m.id }))
      };
    }
    case "setJewelEvents": {
      return {
        ...state,
        jewelEventsById: listToDict(jewelEvents, i => i.id),
        jewelEventOptions: jewelEvents.filter(e => e.active).map(e => ({ label: e.name, value: e.id }))
      };
    }
    case "setNetworks": {
      return {
        ...state,
        networksById: listToDict(networks, i => i.id),
        networkOptions: networks.map(n => ({ label: n.source.description, value: n.id }))
      };
    }
    case "setRegions": {
      return {
        ...state,
        regionsById: listToDict(regions, i => i.id),
        regionOptions: regions.filter(r => r.active).map(r => ({ label: r.name, value: r.id }))
      };
    }
    case "setShows": {
      return {
        ...state,
        showsById: listToDict(shows, i => i.id),
        showOptions: shows.filter(s => s.active).map(s => ({ label: s.name, value: s.id }))
      };
    }
    case "setTransmissionMethods": {
      return {
        ...state,
        transmissionMethodsById: listToDict(transmissionMethods, i => i.id),
        transmissionMethodOptions: transmissionMethods.filter(t => t.active).map(t => ({ label: t.name, value: t.id }))
      };
    }
    case "setTransmits": {
      return {
        ...state,
        transmitsById: listToDict(transmits, i => i.id),
        transmitOptions: transmits.filter(c => c.active).map(c => ({ label: c.name, value: c.id }))
      };
    }
    case "setTransmitDestinations": {
      return {
        ...state,
        transmitDestinationsById: listToDict(transmitDestinations, i => i.id),
        transmitDestinationOptions: transmitDestinations
          .filter(d => d.active)
          .map(d => ({ label: d.name, value: d.id }))
      };
    }
    case "setTransmitFormats": {
      return {
        ...state,
        transmitFormatsById: listToDict(transmitFormats, i => i.id),
        transmitFormatOptions: transmitFormats.filter(f => f.active).map(f => ({ label: f.name, value: f.id }))
      };
    }
    default:
      return state;
  }
};

const IgspProvider = ({ children }: ReactProps) => {
  // hooks
  const {
    loggedIn,
    roleInfo: { adminUser, igspUser, igspAdminUser, igspRequestApproverUser, igspEngineeringApproverUser }
  } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  // initialize data
  const queries = useQueries({
    queries: [
      {
        enabled: loggedIn && (adminUser || igspAdminUser || igspRequestApproverUser || igspEngineeringApproverUser),
        queryKey: ["clientBroadcasters"],
        queryFn: () => getIgspClientBroadcasters(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["countries"],
        queryFn: () => getIgspCountries(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["decoderBrands"],
        queryFn: () => getIgspDecoderBrands(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["decoderModels"],
        queryFn: () => getIgspDecoderModels(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["jewelEvents"],
        queryFn: () => getIgspJewelEvents(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["networks"],
        queryFn: () => getIgspNetworks(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["regions"],
        queryFn: () => getIgspRegions(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["shows"],
        queryFn: () => getIgspShows(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["transmissionMethods"],
        queryFn: () => getIgspTransmissionMethods(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["transmits"],
        queryFn: () => getIgspTransmits(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["transmitDestinations"],
        queryFn: () => getIgspTransmitDestinations(true)
      },
      {
        enabled: loggedIn && (adminUser || igspUser),
        queryKey: ["transmitFormats"],
        queryFn: () => getIgspTransmitFormats(true)
      }
    ]
  });

  // constants
  const [
    { data: clientBroadcasters },
    { data: countries },
    { data: decoderBrands },
    { data: decoderModels },
    { data: jewelEvents },
    { data: networks },
    { data: regions },
    { data: shows },
    { data: transmissionMethods },
    { data: transmits },
    { data: transmitDestinations },
    { data: transmitFormats }
  ] = queries;
  const loading = queries.some(q => q.isLoading);

  // effects
  useEffect(() => {
    if (clientBroadcasters) {
      dispatch({ type: "setClientBroadcasters", clientBroadcasters });
    }
  }, [clientBroadcasters, dispatch]);

  useEffect(() => {
    if (countries) {
      dispatch({ type: "setCountries", countries });
    }
  }, [countries, dispatch]);

  useEffect(() => {
    if (decoderBrands) {
      dispatch({ type: "setDecoderBrands", decoderBrands });
    }
  }, [decoderBrands, dispatch]);

  useEffect(() => {
    if (decoderModels) {
      dispatch({ type: "setDecoderModels", decoderModels });
    }
  }, [decoderModels, dispatch]);

  useEffect(() => {
    if (jewelEvents) {
      dispatch({ type: "setJewelEvents", jewelEvents });
    }
  }, [jewelEvents, dispatch]);

  useEffect(() => {
    if (networks) {
      dispatch({ type: "setNetworks", networks });
    }
  }, [networks, dispatch]);

  useEffect(() => {
    if (regions) {
      dispatch({ type: "setRegions", regions });
    }
  }, [regions, dispatch]);

  useEffect(() => {
    if (shows) {
      dispatch({ type: "setShows", shows });
    }
  }, [shows, dispatch]);

  useEffect(() => {
    if (transmissionMethods) {
      dispatch({ type: "setTransmissionMethods", transmissionMethods });
    }
  }, [transmissionMethods, dispatch]);

  useEffect(() => {
    if (transmits) {
      dispatch({ type: "setTransmits", transmits });
    }
  }, [transmits]);

  useEffect(() => {
    if (transmitDestinations) {
      dispatch({ type: "setTransmitDestinations", transmitDestinations });
    }
  }, [transmitDestinations]);

  useEffect(() => {
    if (transmitFormats) {
      dispatch({ type: "setTransmitFormats", transmitFormats });
    }
  }, [transmitFormats]);

  return <IgspContext.Provider value={{ ...state, loading, dispatch }}>{children}</IgspContext.Provider>;
};

export { IgspContext, IgspProvider };
