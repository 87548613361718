import OktaAuth from "@okta/okta-auth-js";

const CLIENT_ID = import.meta.env.VITE_OKTA_CLIENT_ID;
const ISSUER = import.meta.env.VITE_OKTA_ISSUER;
const REDIRECT_URI = import.meta.env.VITE_OKTA_REDIRECT_URI;

const oktaAuth = new OktaAuth({
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email", "groups"],
  pkce: true,
  tokenManager: {
    expireEarlySeconds: 60
  }
});

export { oktaAuth };
